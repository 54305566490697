import React, { useRef, useState, useEffect } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "../SessionTimeoutDialog";
import { useAuth } from '../../hooks/auth';

const SessionTimeout = ({ save = null, answers, moduleID, statusNumber }) => {
  const [timeoutModalShow, setTimeoutModalShow] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const { signOut, user, userRoles } = useAuth();
  const userObject = useState(typeof (user) === "string" ? JSON.parse(user) : user);
  const idleTimer = useRef(null);

  const TIME_UNTIL_LOGOUT = 300;
  const TIME_UNTIL_IDLE = 55;
  const handleLogout = async (isTimedOut = false) => {
    setTimeoutModalShow(false);
    if (user) {
      if (save != null) {
        if ((userRoles.includes("RoleAdmin") && statusNumber == 8) //Reactivated By Admin
          || (userRoles.includes("RoleInfoHolder") && (statusNumber == null || statusNumber == 1 || statusNumber == 3 || statusNumber == 5 || statusNumber == 6 || statusNumber == 8)) //Not Saved As Draft OR Save as Draft OR Reopened By Local OR Reopened By Global OR Version Approved OR Reactivated By Admin
          || (userRoles.includes("RoleApprover") && userObject.companyID == null && statusNumber == 5)) {
          if (userRoles.includes("RoleAdmin") && statusNumber == 8) {
            await save(answers, moduleID, true);
            signOut();
          }
          else {
            await save(answers, moduleID, false);
            signOut();
          }
        }
      }
      else {
        signOut();
      }
    }
  };

  useEffect(() => {
    if (timeoutCountdown === 0 && timeoutModalShow) {
      handleLogout(true);
    }
  }, [timeoutCountdown]);

  const onIdle = () => {
    if (user && !timeoutModalShow) {
      setTimeoutModalShow(true);
      setTimeoutCountdown(TIME_UNTIL_LOGOUT); // start countdown
    }
  };

  useEffect(() => {
    let countdownInterval;
    if (timeoutModalShow && timeoutCountdown > 0) {
      countdownInterval = setInterval(() => {
        setTimeoutCountdown(prevCount => prevCount - 1);
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [timeoutModalShow, timeoutCountdown]);

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={() => {
          if (!timeoutModalShow) {
            setTimeoutCountdown(0);  // reset countdown
          }
        }}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 60 * TIME_UNTIL_IDLE} // 55 minutes
      />
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={() => {
          setTimeoutModalShow(false);
          setTimeoutCountdown(0);  // reset countdown
        }}
        show={timeoutModalShow}
      />
    </>
  );
}

export default SessionTimeout;
